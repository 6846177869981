@media (max-width: 767px) {
  .alice-carousel__wrapper {
    overflow-x: auto !important;
  }
}

@media (max-width: 320px) {
  .MuiContainer-root {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

html {
  scroll-behavior: smooth;
}

html body {
  scroll-behavior: smooth;
  overflow: auto;
  padding-right: 0px !important;
}

.rtl-transform {
  transform: scaleX(1);
}

body[dir="rtl"] .rtl-transform {
  transform: scaleX(-1);
}

body[dir="rtl"] .alice-carousel {
  width: 100%;
  direction: rtl;
}

.slick-prev:before,
.slick-next:before {
  content: none !important;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -15px;
  z-index: 10;
  background-color: white;
}

[dir="rtl"] .slick-prev {
  right: -15px;
  left: auto;
  background: white;
}

.slick-prev:before,
.slick-next:before {
  color: transparent !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .scroll-bar_scroll-bar--up-to-tablet__q1vIo {
    overflow-x: auto;
    overflow-y: unset;
    padding-bottom: 15px;
  }
}

.product-list-container {
  overflow-x: auto;
  white-space: nowrap;
}

.product-list {
  display: flex;
  padding: 10px;
}

.product-item {
  flex: 0 0 1 !important;
  margin: 0 10px;
}

[dir="rtl"] .product-list {
  direction: rtl;
}

.brand-list {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 10px 0;
}

.brand-list > div {
  flex: 0 0 auto;
  padding: 0 10px;
}

.MuiPickersCalendarHeader-root {
  justify-content: space-between;
}

.MuiPickersCalendarHeader-labelContainer {
  margin-right: 0px !important;
}

[dir="rtl"] [data-testid="ArrowLeftIcon"],
[dir="rtl"] [data-testid="ArrowRightIcon"] {
  transform: scaleX(-1) !important;
}

[dir="rtl"] .MuiFormHelperText-root {
  text-align: right;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-container {
  text-align: center;
  color: #ffffff;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 16px;
}

.selectitem {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: whitesmoke;
  padding: 25px;
}

.selectitem div {
  margin-bottom: 10px;
  cursor: pointer;
}

.selectitem img:hover {
  opacity: 0.5;
}

.selectitem label {
  margin-bottom: 5px;
  font-weight: bold;
}

.selectitem select {
  width: 100%;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding: 5px;
  width: 75%;
}

.product {
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product:hover {
  transform: translateY(-5px);
}

.product p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.filterAll {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filterAll button {
  outline: none;
  background: #000;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

span .cat {
  font-weight: 900;
  font-size: 22px;
}

.map-container {
  border: 1px solid #ffffffb6;
  border-radius: 4px;
  overflow: hidden;
}

.error {
  margin-left: "0px";
  margin-right: "0px";
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
}

.hover-opacity {
  transition: opacity 0.3s ease;
}

.hover-opacity:hover {
  opacity: 0.95;
}

#elfsighDiv {
  display: flex;
  align-self: center;
}

#productView {
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.loader-container {
  display: flex;
  align-items: center;
}

.dot {
  width: 16px;
  height: 16px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #000; /* Change the color as needed */
  animation: dance 0.6s infinite alternate;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dance {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.loading-text {
  margin-left: 10px;
  font-size: 1.2rem;
  color: #333; /* Change the color as needed */
}

.MuiPickersDay-root:not(.Mui-selected) {
  border: none !important;
}
