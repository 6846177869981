body {
  margin: 0;
  padding: 0 !important;
  font-family: "DINNEXT";
  overflow: unset !important;
}

@font-face {
  font-family: "DINNEXT";
  src: url("../public/assets/fonts/DIN\ NEXT™\ ARABIC\ REGULAR.otf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DINNEXT";
  src: url("../public/assets/fonts/DIN\ NEXT™\ ARABIC\ LIGHT.otf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "DINNEXT";
  src: url("../public/assets/fonts/DIN\ NEXT™\ ARABIC\ REGULAR.otf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../public/assets/fonts/Jost-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../public/assets/fonts/Jost-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../public/assets/fonts/Jost-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.starColor {
  color: "red";
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #a4a1aa33;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a4a1aa33;
}

/* Ensure the scrollbar is visible only on scroll */

[dir="rtl"] .css-1ore04p-MuiPickersCalendarHeader-labelContainer {
  margin-right: 0px;
  margin-left: auto;
}

[lang="ar"] {
  font-family: "Arabic";
}


.no-spin {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}