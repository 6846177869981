.header-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.header-container.show {
  max-height: 100px;
  /* Adjust this value as needed */
}

.slide-content {
  background-color: #f0f0f0;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.search-mini_searchmini__qAJeI {
  background: #000c;
  /* padding-top: 128px; */
}

.search-mini_searchmini__qAJeI {
  bottom: 0;
  left: 0;
  padding-top: 45px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: 6000;
}
.backdrop-box {
  width: 100%;
  padding-top: 6vh;
}
